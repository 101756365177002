.portfolio a {
  color: grey;
}

.portfolio .nav-tabs {
  border-color: white white #28a745;
}

.portfolio .nav-link.active {
  border-color: #28a745 #28a745 white !important;
}
