@media (max-width: 690px) {
  .form-control {
    font-size: 0.5rem !important;
  }
  .display-4 {
    font-size: 2rem !important;
  }
}

.brand-button {
  display: flex !important;
  background: none !important;
  padding: 0 !important;
  color: black !important;
  width: 100%;
  border: 1px solid black;
  border-radius: 7px !important;
  text-decoration: none;
}

.btn-link:hover {
  text-decoration: none !important;
}

.brand-button > img {
  width: 50px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.linkedin-border {
  border-color: #0e76a8 !important;
}

.instagram-border {
  border-color: #8a3ab9 !important;
}

.youtube-border {
  border-color: #c4302b  !important;
}