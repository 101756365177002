.Container {
  position: relative;
  text-align: center;
  color: white;
}

.Credit {
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 3px 5px;
  font-size: 10px;
  background-color: rgba(0,0,0,0.40);
}

img {
  width: 100%;
}

.Credit > a {
  color: white !important;
}