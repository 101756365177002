@import url(https://fonts.googleapis.com/css2?family=Yellowtail&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  scroll-behavior: smooth;
}


.Brand {
  font-family: Yellowtail;
  color: white;
  -webkit-transform: rotate(-2deg);
          transform: rotate(-2deg);
}

.Brand > a {
  color: #fff !important;
  font-size: 1rem;
}

@media (max-width: 690px) {
  .DesktopNavBar a {
    font-size: 0.5rem;
  }
}
.Layout {
  padding: 0 !important;
  margin-top: 50px;
}
.Container {
  position: relative;
  text-align: center;
  color: white;
}

.Credit {
  position: absolute;
  bottom: 0px;
  right: 0px;
  padding: 3px 5px;
  font-size: 10px;
  background-color: rgba(0,0,0,0.40);
}

img {
  width: 100%;
}

.Credit > a {
  color: white !important;
}
@media (max-width: 900px) {
  .progress-table {
    font-size: 0.5rem !important;
  }
}
.carousel-item {
  height: 150px !important;
}
.Hero-Overlay {
  position: absolute !important;
  top: 12%;
  left: 10%;
  padding: 1.2rem;
  background-color: rgba(255,255,255,0.40);
}

.Section-Divider {
  height: 80px;
  margin-top: -30px;
}

@media (max-width: 690px) {
  h1 {
    font-size: 1.5rem !important;
  }

  .Home p, .Home a {
    font-size: 0.5rem !important;
  }
}

@media (min-width: 1024px) {
  h1 {
    font-size: 4rem !important;
  }
}
.portfolio-card .card-footer {
  background-color: #fff;
  border-top: none !important;
}

.portfolio-card img {
  max-height: 250px;
}
.portfolio a {
  color: grey;
}

.portfolio .nav-tabs {
  border-color: white white #28a745;
}

.portfolio .nav-link.active {
  border-color: #28a745 #28a745 white !important;
}

@media (max-width: 690px) {
  .form-control {
    font-size: 0.5rem !important;
  }
  .display-4 {
    font-size: 2rem !important;
  }
}

.brand-button {
  display: -webkit-flex !important;
  display: flex !important;
  background: none !important;
  padding: 0 !important;
  color: black !important;
  width: 100%;
  border: 1px solid black;
  border-radius: 7px !important;
  text-decoration: none;
}

.btn-link:hover {
  text-decoration: none !important;
}

.brand-button > img {
  width: 50px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.linkedin-border {
  border-color: #0e76a8 !important;
}

.instagram-border {
  border-color: #8a3ab9 !important;
}

.youtube-border {
  border-color: #c4302b  !important;
}
