@import url('https://fonts.googleapis.com/css2?family=Yellowtail&display=swap');

.Brand {
  font-family: Yellowtail;
  color: white;
  transform: rotate(-2deg);
}

.Brand > a {
  color: #fff !important;
  font-size: 1rem;
}

@media (max-width: 690px) {
  .DesktopNavBar a {
    font-size: 0.5rem;
  }
}